import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const termsPage: React.FC<{}> = () => {

  const priceRef = React.useRef('pricing');
    const cancelRef = React.useRef('cancel');
  function scrollToComponent() {
	switch (window.location.hash) {
	  case '#cancel' :
		cancelRef.current.scrollIntoView({ behavior: 'smooth' /*or smooth*/});
		cancelRef.current.focus();		
	  case '#pricing' :
		priceRef.current.scrollIntoView();
		priceRef.current.focus();		
	}
  }

  React.useEffect( () => scrollToComponent(), [] )
  
  return (

	<PrimaryLayout>
		<SEO title="terms and conditions" />
		<Container py="40px">
			<h1>Terms &amp; Conditions</h1>
			<p>NCOM Digital Solutions LLP (&ldquo;NCOM&rdquo;) is the owner of the domain chat2order.in, the websites <a href="http://www.chat2order.in">www.chat2order.in</a>,</p>
			<p><a href="http://www.shops.chat2order.in">www.shops.chat2order.in</a> &amp; the platform build on WhatsApp (collectively &rdquo;<strong>chat2order websites &amp; platforms</strong>&rdquo;).</p>
			<p><strong>Data Collection</strong></p>
			<p><strong>Chat2order websites &amp; platforms</strong> collects personally identifiable information about you and information about the pages on website you visit/access, the links you click on the site, the number of times you access the page and any such browsing information. But we respect your privacy. Please check our Privacy policy for more information.</p>
			<p ref={priceRef} id='pricing'><strong>Pricing</strong></p>
			<p>All the products listed on <strong>chat2order websites &amp; platforms</strong>will be sold in INR. The product price listed are MRP or less unless otherwise specified. The prices mentioned at the time of&nbsp;ordering, with any applicable offers or discounts&nbsp;will be the prices charged in INR on the date of the placing order from our platform. Although prices of most of the products do not fluctuate on a daily&nbsp;basis but some&nbsp;of product price do change over time. In case the prices are higher or lower on the date of&nbsp;delivery&nbsp;no additional charges will be collected or refunded as the case may be at the time of the delivery of the order.</p>
			<p ref={cancelRef} id='cancel'><strong>Order Cancellation</strong></p>
			<p>You as a customer cannot cancel your order once you have completed payment in<strong> chat2order websites &amp; platforms</strong>. Before that you have the flexibility to validate, verify all the details of the product until you place order &amp; make payment. However, if there is a genuine reason for you to cancel your order after making payment, please call our number +91.87776 75366 or drop a note to info@chat2order.in. In such a case we will try our best to refund any payments already made by you for the order if seller has not started delivering or fulfilling your order request. The refund should be credited to you within 5 business days.</p>
			<p>If we suspect any fraudulent intent by any customer or any transaction which&nbsp;defies&nbsp;the terms &amp; conditions of using the <strong>chat2order websites &amp; platforms</strong>, we at our sole discretion could cancel such orders. We may maintain a negative list of all fraudulent transactions and customers and would deny access to them or cancel any orders placed by them.</p>			
			<p><strong>Return &amp; Refunds</strong></p>
			<p>We have a refund policy as governed by the Local seller that is supplying the product. This entitles you as our customers to return the product after delivery if due to some reason they are not satisfied with the quality product to the seller directly within 2 days. If there is a disconnect between you &amp; our seller, please drop a note to <a href="mailto:info@chat2order.in">info@chat2order.in</a> explaining the issue preferably with pictures &amp;/or videos. If there is a genuine reason for you to request refund or return, we will try our best to reason with seller to provide a replacement of refund as soon as possible. However, if a seller cancels your product for unavailability of any other unavoidable delivery, fulfilment or internal business issues, we will try to refund you your full money within 5 business days.</p>			
			<p><strong>Shipping&nbsp;</strong></p>
			<p>We have a shipping policy as governed by the Local seller that is supplying the product. Unless mentioned explicitly on the product information, Shipping fees are calculated on the overall order weight, volume and the total value of order. Most sellers have a threshold above which they provide free shipping. We calculate the shipping fee and collect it during placing the order. This fee is passed on to the seller for shipping &amp; fulfilling your delivery. The Shipping rules &amp; free threshold order value are advertised on seller pages &amp; we request you to pay attention to it before placing order. We work with sellers to ship the product within 3 business days of order confirmation.</p>
			<p>If there is a disconnect between you &amp; our seller, please call our number +91.87776 75366 or drop a note to <a href="mailto:info@chat2order.in">info@chat2order.in</a> explaining the issue we will try our best to reason with the seller to address the issue. However, if a seller cancels your product for shipping challenges, we will refund your full money within 5 business days.</p>
			<p><strong>You Agree and Confirm</strong></p>
			<ul>
			<li>That in the event that a non-delivery occurs on account of a mistake by you (i.e. wrong name or address or any other wrong information) any extra cost incurred by chat2order for redelivery shall be borne by you.</li>
			<li>That you will use the services provided by the <strong>chat2order websites &amp; platforms</strong> for lawful purposes only and comply with all applicable laws and regulations while using and transacting.</li>
			<li>You will provide authentic and true information in all instances where such information is requested of you. The platform &amp; company reserves the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details are found not to be true (wholly or partly), it has the right in its sole discretion to reject the registration and delist you from using the Services&nbsp;without prior intimation whatsoever.</li>
			<li>That you are accessing the services available on this <strong>chat2order websites &amp; platforms</strong> and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction.</li>
			<li>That the address at which delivery of the product ordered by you is to be made will be correct and proper in all respects.</li>
			<li>That before placing an order you will check the product description carefully. By placing an order for a product you agree to be bound by the conditions of sale included in the item&rsquo;s description.</li>
			</ul>
			<p><strong>Display of Products in Digital format</strong></p>
			<p>We have made every effort to display the colours of products that appear on the <strong>chat2order websites &amp; platforms</strong> as accurately as possible. However, as the actual colours you see will depend on your device used &amp; we cannot guarantee the representation of display will be accurate. Please use judgement of digital image of physical items &amp; corroborate with product description before you buy.&nbsp;</p>
			<p><strong>Eligibility</strong></p>
			<p>Services of our platform would be available to only select geographies in India. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the <strong>chat2order websites &amp; platforms</strong> only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited.</p>
			<p><strong>Governing Law and Jurisdiction</strong></p>
			<p>This User Agreement shall be construed in accordance with the applicable laws of India. The Courts at Kolkata shall have exclusive jurisdiction in any proceedings arising out of this agreement. Any dispute or difference either in interpretation or otherwise, of any terms of this User Agreement between the parties hereto, the same shall be referred to an independent arbitrator who will be appointed by NCOM and his decision shall be final and binding on the parties hereto. The arbitration shall be held in Kolkata. The High Court of&nbsp;Kolkata alone shall have the jurisdiction and the Laws of India shall apply.</p>
			<p><strong>Indemnity</strong></p>
			<p>You agree to defend, indemnify and hold harmless NCOM, its employees, directors, officers, agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney&rsquo;s fees, caused by or arising out of claims based upon your actions or inactions, which may result in any loss or liability to NCOM or any third party including but not limited to breach of any warranties, representations or undertakings or in relation to the non-fulfilment of any of your obligations under this User Agreement or arising out of the your violation of any applicable laws, regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the expiry or termination of this User Agreement.</p>
			<p><strong>Modification of Terms &amp; Conditions of Service</strong></p>
			<p>NCOM may at any time modify the Terms &amp; Conditions of Use of the <strong>chat2order websites &amp; platforms</strong> without any prior notification to you. You can access the latest version of these Terms &amp; Conditions at any given time on the Site. You should regularly review the Terms &amp; Conditions. In the event the modified Terms &amp; Conditions is not acceptable to you, you should discontinue using the Service. However, if you continue to use the Service you shall be deemed to have agreed to accept and abide by the modified Terms &amp; Conditions of Use of this Site.</p>
			<p><strong>Termination</strong></p>
			<p>This User Agreement is effective unless and until terminated by either you or NCOM. You may terminate this User Agreement at any time, provided that you discontinue any further use of this Website &amp; platforms. NCOM may terminate this User Agreement at any time and may do so immediately without notice, and accordingly, deny you access to the website &amp; platforms. Such termination will be without any liability to NCOM. Upon any termination of the User Agreement by either you or NCOM, you must promptly destroy all materials downloaded or otherwise obtained from this website &amp; platforms, as well as all copies of such materials, whether made under the User Agreement or otherwise.&nbsp;NCOM&rsquo;s&nbsp;right to any Comments shall survive any termination of this User Agreement. Any such termination of the User Agreement shall not cancel your obligation to pay for the product already ordered from the Website &amp; Platforms or affect any liability that may have arisen under the User Agreement.</p>
			<p><br /><br /><br /></p>
		</Container>		
	</PrimaryLayout>
);
}

export default termsPage;